@import '../../../../../static/css/global/global.scss';

$faded-primary-color: rgba($color-primary, .2);
$faded-primary-color-2: rgba($color-primary, .5);

.search-box{
    &__parent{
        width: 100%;
        opacity: 0;
        height: 0;
        visibility: hidden;
        position: absolute;
        width: 61%;
        left: 7.5%;
        // transition: height .5s ease, opacity .5s ease, visibility .5s ease;
        background-color: #ffffff;
        border-radius: 0px 0px 8px 8px;

        @media screen and (max-width: 1024px) {
            width: 100%;
            left: 0px;
        }

        .background-overlay-1{
            top: 0px !important;
            opacity: 0 !important;
        }

        .background-overlay-1,
        .background-overlay{
            position: fixed;
            height: 100%;
            width: 100%;
            background-color: #20262E;
            opacity: 0.3;
            left: 0px;
            z-index: 1;
            top: 98px;

            @media screen and (max-width: 1024px) {
                background-color: #FFF;
                opacity: 1;
            }
        }


        &.show{
            // height: 300px;
            // height: auto;
            opacity: 1!important;
            visibility: visible!important;
            transition: height .5s ease, opacity .5s ease, visibility .5s ease;
            @media(max-width:767px){
                box-shadow: 0px 2px 26px #00000029;
                
            }
        }
        &.search_close{
            transition: height .5s ease, opacity .5s ease, visibility .5s ease;
        }
    }

    &__container{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding:0px 12.5px 45px;
        background-color: #ffffff;
        z-index: 99;
        position: absolute;;
        @media (min-width: 1023px){
            display: none;
            padding:0px 0px 0px;
            max-width: 1071px;
            margin: 0 auto;
            background-color: #ffffff;
            z-index: 99;
            border-radius: 0px 0px 8px 8px;
            margin-top: -20px;
            left: 27%;
        }

        @media screen and (max-width: 1024px) {
            padding:0px 16px 45px;
            
        }

        h6{
            margin: 0;
        }
        .close-btn{
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 999999;
        }
    }

    &__inner{
        @media (min-width: 768px){
            padding: 0 16px 30px;
        }
    }

    &__input{
        display: flex;
        position: relative;
        margin-top: 20px;
        align-items: center;
        border-bottom: 4px solid #F7F7F7;
        padding-bottom: 18px;

        svg{
            margin-right: 16px;
        }

        input{
            background: transparent;
            border: 0;
            width: 100%;
            padding: 11px 7.5px 11px 31.5px;
            @include fontStyle($fontFamily-default, 14px, 500, 26px, 0px, $faded-primary-color-2);
            border-bottom: 1px solid $faded-primary-color;
            @media (min-width: 768px) {
                padding: 11px 68px 11px 98px;
                @include fontStyle($fontFamily-default, 18px, 500, 26px, 0px, $faded-primary-color-2);
            }

            @media screen and (max-width: 1024px) {
                padding: 13px 12px;
                border-bottom: none;
                background-color: #E8E9EA;
                border-radius: 4px;

            }

            &::placeholder{
                color: $faded-primary-color-2;
            }
        }

        img{
            position: absolute;
            // right: 11px;
            left: 7.5px;
            top: 50%;
            transform: translateY(-50%);
            @media (min-width: 768px){
                left: 68px;
            }
        }
    }

    &__title{
        display: flex;
        border-left: 4px solid #FFBE00; 
        padding-left: 8px;
        margin-bottom: 16px;
        text-transform: capitalize;
        @include fontStyle($font-family-barlow, 18px, 500, 22px, 0px, #20262E);
        @media (min-width: 768px){
            @include fontStyle($font-family-barlow, 18px, 500, 22px, 0px, #20262E);
        }

        span{
            @include fontStyle($fontFamily-default, 12px, 500, 26px, 0px, #808EA1);
            text-decoration: underline;
            cursor: pointer;
            margin-left: auto;
        }
    }

    &__TS{
        margin-top: 9px;
        @media (min-width: 768px) {
            margin-top: 8px;
        }

        &__items{
            margin-top: 10px;
            display: flex;
            column-gap: 5px;
            @media (min-width: 768px) {
                column-gap: 20px;
            }
        }
        
        &__item{
            display: flex;
            flex-direction: column;
            // width: calc(100%/3 - (30px/3));
            width: 80px;
            row-gap: 3px;
            @media (min-width: 768px) {
                row-gap: 10px;
                width: 102px;
            }

            &__img-container{
                width: 100%;
                height: 82px;
                border: 1px solid $faded-primary-color;
                border-radius: 10px;
                // background-color: $color-primary;
                position: relative;

                img{
                    width: auto;
                    max-width: 100%;
                    height: 100%;
                    max-height: 81px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    border-radius: 10px;
                    transform: translate(-50%, -50%);
                    // background-color: $color-primary;
                    // mix-blend-mode: multiply;
                }
            }
            &__text{
                @include text-overflow(3);
                @include fontStyle($fontFamily-default, 12px, 400, 14px, 0px, rgba($color-primary, .9));
                @media (min-width: 768px){
                    @include fontStyle($fontFamily-default, 14px, 400, 18px, 0px, $color-primary);
                }
            }
        }

    }

    &__RS{
        margin-top: 22px;
        @media (min-width: 768px) {
            margin-top: 18px;
        }

        &__items{
            display: flex;
            flex-direction: column;
            margin-top: 11px;
            row-gap: 10px;
            @media (min-width: 768px) {
                margin-top: 8px;
            }
        }

        &__item{
            display: flex;
            align-items: center;

            p{
                margin-left: 10.23px;
                @include fontStyle($fontFamily-default, 16px, 500, 20px, 0px, #20262E);
                @media screen and (max-width: 1024px) {
                    width: 90%;
                }
                @media (min-width: 768px){
                    @include fontStyle($fontFamily-default, 16px, 500, 20px, 0px, #20262E);
                    word-break: break-word;
                }
            }

            img.cancel-btn{
                margin-left: auto;
                cursor: pointer;
            }
        }
    }

    &__search-results{
        margin-top: 16.9px;
        display: flex;
        @media(max-width:1024px){
            margin-top: 8.9px;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: auto;
            max-height: 500px;
        }

        @media screen and (min-width: 1024px) {
            padding: 0px 16px 50px;
            overflow: auto;
            @include  scrollbar-thin();
        }
    }

    &__SP{
        flex-grow: 1;
        @media(max-width:767px){
            margin-top: 20px;
        }
        

        &__items{
            margin-top: 25.44px;
            display: grid;
            row-gap: 11px;
            column-gap: 50px;
            width: 100%;
            max-height: 299px;
            @media(max-width:767px){
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                flex-wrap: nowrap;
                height: auto;
            }
        }

        &__item{
            display: flex;
            align-items: center;
            column-gap: 16px;
            width: 100%;
            padding-bottom: 14px;
            border-bottom: 1px solid rgba($color-primary, .2);
            background-color: #ffffff;

            img{
                height: 70px;
                object-fit: contain;
                width: 50px;
                min-width: 50px;
            }

            h6{
                @include fontStyle($fontFamily-default, 16px, 400, 21px, 0px, $color-primary);
                @include text-overflow(1);
            }
        }
    }

    &__BC{
        width: 440px;
        border-right: 1px solid rgba($color-primary, .3);
        margin-right: 38px;
        @media (max-width: 767px){
            width: 100%;
            border-right: 0;
            margin-right: 0;
        }

        &__items{
            margin-top: 18px;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            @media (max-width: 767px){
                margin-top: 11px;
            }

            h6{
                @include fontStyle($fontFamily-default, 18px, 400, 24px, 0px, $color-primary);
                @media(max-width: 767px){
                    @include fontStyle($fontFamily-default, 13px, 400, 17px, 0px, $color-primary);
                }

                span {
                    @include fontStyle($fontFamily-default, 18px, 400, 24px, 0px, $color-primary);
                    @media(max-width: 767px){
                        @include fontStyle($fontFamily-default, 13px, 400, 17px, 0px, $color-primary);
                    }
                    &.fnt-bld {
                        font-weight: 600;
                    }
                }
            }
        }
    }

}
