.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loylt-popup-cntnr {
    background-color: #0000001A;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    @extend .flex-center;

    @media screen and (max-width: 1024px) {
        align-items: flex-end;
        background-color: rgba(0, 0, 0, .4);
    }

    .popup-body-cntnr {
        background: #FFFFFF;
        border-radius: 24px;
        box-shadow: 0px -5px 10px 0px #0000001A;
        padding: 32px 34px;
        position: relative;
        min-width: 400px;

        @media screen and (max-width: 1024px) {
            min-width: 100%;
            border-radius: 16px 16px 0px 0px;
            padding: 19px 21px;
        }

        .add-to-cart-btn {
            width: 100%;
            background: #292D67;
            border-radius: 12px;
            padding: 13px;
            @extend .flex-center;
            margin-top: 32px;

            @media screen and (max-width: 1024px) {
                margin-top: 28px;
            }

            button {
                font-size: 16px;
                font-weight: 600;
                line-height: 18px;
                text-align: left;
                color: #FFF;
                background-color: transparent;
                border: none;

                @media screen and (max-width: 1024px) {
                    font-size: 14px;
                }
            }
        }

        .close {
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
        }

        .popup-header-cntnr {
            @extend .flex-center;
            flex-direction: column;
            padding-bottom: 32px;
            width: 100%;

            @media screen and (max-width: 1024px) {
                padding-bottom: 28px;
            }

            .second-heading {
                letter-spacing: 0.07em;
                text-align: center;
                padding-top: 12px;
                line-height: 18px;
                font-weight: 600;
                font-size: 16px;
            }
        }

        .benefit-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 9px;

            .first-benefit-cntnr {
                @extend .flex-center;
                justify-content: flex-start;
                gap: 7px;

                .dtl-cntnr {

                    .bold {
                        @extend .normal;
                        font-weight: 600 !important;
                    }

                    .normal {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        padding-left: 5px;
                        color: #002140;

                        @media screen and (max-width: 1024px) {
                            font-size: 12px;
                        }
                    }
                }
            }

            .second-benefit-cntnr {
                @extend .first-benefit-cntnr
            }
        }

        .premium-info-cntnr {
            padding: 15px 17px 15px 13px;
            border-radius: 12px;
            border: 0.5px solid #E57321;
            margin-top: 32px;
            width: 100%;
            @extend .flex-center;

            @media screen and (max-width: 1024px) {
                margin-top: 28px;
            }

            .premium-ttl-cntnr {
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                color: #000000;

                @media screen and (max-width: 1024px) {
                    font-size: 12px;
                }
            }

            .prem-amnt-cntnr {
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                color: #292D67;
                margin-left: auto;
                @media screen and (max-width: 1024px) {
                    font-size: 12px;
                }

                b {
                    font-weight: 700;
                }
            }
        }

    }
}

.loader{
    width: 413px;
    height: 221px;
    border-radius: 16px;
}