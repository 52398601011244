@import 'src/static/css/global/global.scss';

@mixin headingStyle () {
    color: #9B9B9B;

    font-size: 18px;
    line-height: 1.21;
}

.viewpoints {
    float: left;
    margin: 0 5px 0 0;

    line-height: 18px;

}

.mrg-t5 {
    margin: 5px 0 0 0;
}

.points {
    float: left;
    font-size: 12px;
    color: #9B9B9B;
    line-height: 18px;
}

.display-inline-block {
    display: inline-block;
    margin: 0 0 0 5px;
}

.address-progress-tab {
    text-align: center;
    margin-top: 30px;

    .page-title {
        padding: 5px 10px;
        margin: 0;
        color: #7c7f80;
        border-bottom: 0;
        position: relative;
        padding-bottom: 10px;
        text-transform: none;
        // font-weight: 600;


        &:after {
            content: "";
            height: 2px;
            background: #dfdfdf;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }

        &.current {
            color: $blue;


            &:after {
                background: $blue;
            }
        }
    }
}

.order-summary-widget {
    .order-total {
        padding-top: 12px;
        padding-bottom: 13px;

        .col-sm-6-mb-0 {
            .total-payable {
                font-size: 21px;
            }
        }
    }

    .col-sm-6 {
        width: 50%;
        float: left;
        font-size: 14px;
        margin: 0 0 8px;
    }

    .col-sm-12 {
        width: 100%;
        float: left;
        font-size: 14px;
        margin: 0 0 8px;
    }

    .col-sm-6-mb-0 {
        width: 50%;
        float: left;
        font-size: 16px;
        margin-top: 6px;
    }

    .text-right {
        text-align: right;
        font-size: 14px;

        span.js-redeem-pt {
            font-weight: bold;
            font-size: 16px;
            cursor: pointer;
            color: #21a0b0;
            line-height: 1.5;
            display: block;
        }
    }

    .separator {
        border: 1px solid $grey-11;
        margin-top: 5px;
        margin-bottom: 5px;
        box-shadow: none;
        width: 100%;
        float: left;
    }

    .strikethrough {
        text-decoration: line-through;
    }

    .txt-blue {
        color: #1ebcbd;
        // margin-left: 5px;
        cursor: pointer;
    }

    .cart-sum-tblinner {
        border-radius: 4px;
        position: relative;
        //    background: #E9F8FC;
        // padding: 15px 0;
        width: 100%;
        float: left;
        line-height: 1.42;

        .cart-summary {
            border-left: 0;

            .clear-fix {
                display: inline-block;
                width: 100%;
            }

            .text-right {
                text-align: right;
            }

            .disc-price {
                font-size: 11.2px;
                line-height: 16px;
                color: #25292b;

                * {
                    font-size: inherit;
                    line-height: inherit;
                    color: inherit;
                }
            }

            .proceed-to-checkout {
                margin: 15px 0 15px;
                padding: 0;
                display: inline-block;
                width: 100%;
            }

            .payrow,
            .clearfix,
            .order-summary {
                display: inline-block;
                width: 100%;
            }

            .payrow {
                margin-top: 10px;
            }

            .btn-blue {
                border-radius: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: none;
                padding: 0;
                min-width: 100%;
                font-size: 22px;
                font-weight: 600;
                background: $blue;
                height: 50px;
                line-height: 50px;
                box-sizing: border-box;
                box-shadow: none;
                color: #fff;
                display: inline-block;
                text-align: center;
            }

            .btn-orange {
                border-radius: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: none;
                padding: 0;
                min-width: 100%;
                font-size: 18px;
                border-radius: 25px;
                // font-weight: 600;
                background: $checkout-btn-color;
                height: 50px;
                line-height: 50px;
                box-sizing: border-box;
                box-shadow: none;
                color: #fff;
                display: inline-block;
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        .yousave {
            color: $orange4;
            font-size: 14px;

            position: relative;
            //padding: 0 10px;
            text-align: right;
            box-sizing: border-box;
            line-height: 14px;

            span {
                color: $orange4;
                font-weight: 600;
            }
        }

        .incl-tx-txt {
            text-align: left;
            padding: 10px 0 0 0;
        }

        .hkcashsec {
            text-align: center;

            .loyality-text {
                color: $black2;
                font-size: 12px;
                margin: 0;
                text-align: center;
                font-weight: 400;

                strong {
                    color: $orange4;
                }
            }
        }
    }
}

.address-data {
    width: 100%;
    float: left;

    .checkoutheadings {
        @include headingStyle();
        margin: 20px 0 10px 0;
        border: 0;
        text-transform: capitalize;
    }

    .cart-sum-tblinner {
        padding: 25px;
    }

    .txt-blue {
        color: $blue-1;
    }

    .fnt-caps {
        text-transform: uppercase;
    }

    .fnt-bold {
        font-weight: 600;
    }

    .fnt-sz9 {
        font-size: .8em;
    }

    .fnt-sz14 {
        font-size: 14px;
    }

    .m-t-5 {
        margin-top: 5px;
        display: block;
    }

    p {
        font-size: 14px;
        line-height: 21px;
    }

    p.usrname {
        font-weight: 600;
        // margin-top: 15px;
    }

    p.usrphone {
        font-weight: 600;
        margin-bottom: 5px;
    }

    p.usraddress {
        margin-bottom: 16px;
    }
}

.seperator-blue {
    border-color: #C1EBF0 !important;
}

// cart item
.cart-summary {
    padding: 0;

    .line-item {
        border: none;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        margin-bottom: 20px;
        padding-top: 15px;
        transition: all 1s linear;
        opacity: .9;

        &.bxgy {
            border: 0;
            margin-bottom: 0;

            .variant-desc .wishlist--cart--item-msg a:last-child {
                border: 0;
                padding: 0;
            }
        }

        &>.row {
            margin-left: 0;
            margin-right: 0;
            padding-bottom: 15px;
            display: inline-block;
            width: 100%;
            position: relative;
        }

        .prod-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100px;
            z-index: 10;

        }

        .variant-desc {
            width: 100%;
            position: relative;
            padding-left: 110px;
            display: inline-block;

            .row {
                margin: 0;
            }

            .variant-name {
                width: 100%;
                float: none;

                a {
                    font-weight: 400;
                    color: #25292b;
                    line-height: 20px;
                    font-size: 14px;

                    &:hover {
                        color: #212121;
                    }
                }

                span.pack-item-qty.no-wrap {
                    font-size: 12px;
                    color: #929495;
                    white-space: nowrap;
                }

                span.pack-item-qty.no-wrap * {
                    color: inherit;
                    font-size: inherit;
                }
            }

            .stepper {
                margin: 10px 0 0 0;
            }

            .price {
                width: 100%;
                float: none;
                text-align: left;
                font-weight: 600;
                font-size: 15.4px;
                line-height: 22px;

                div {
                    font-size: inherit;
                    line-height: inherit;

                    span {
                        font-size: inherit;
                        line-height: inherit;
                    }

                }
            }

            p.loyality-text {
                color: $black2;
                display: none;
            }

            .wishlist--cart--item-msg {
                display: none;
            }
        }

        .bxgy-off {
            //  border-top: 1px dashed #d3d4d5;
            padding: 0;
            box-sizing: border-box;
            margin: 0px 0px 0px;
            padding: 5px 15px;


            span.freeBIcon {
                background: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/offer.svg);
                width: 26px;
                height: 26px;
                margin-right: 10px;
                float: left;

                &.freebie-green {
                    background: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/freebiIcon-v1.svg)
                }
            }

            a {
                font-size: 12px;
                font-weight: 400;
                color: #25292b;
                text-decoration: underline;
                line-height: 1.8;
            }

            a.offer-applied {
                color: #000;
                font-weight: 700;
                text-decoration: none;
            }
        }
    }
}

.vita-points-container {
    width: 100%;
    float: left;
    // padding-top: 30px;
    // border-top: 1px solid $grey-11;
    margin: 27px 0 0 0;
}

.payable {
    .sub-text {
        width: 100%;
        float: left;
        font-size: 12px;
        color: #9B9B9B;
        // padding: 0 0 0 28px;
    }
}

.vita-points-redeem {
    .redeem-vips-wrapper {
        width: 100%;
        float: left;
        line-height: 14px;

        .vita-pnts-image {
            left: 28px;
        }

        .redeem-vita-points-section {
            width: 100%;
            float: left;

            .vps-apply-checkbox {
                width: 18px;
                height: 18px;
                float: left;
                cursor: pointer;

                .tbs-checkbox-square {
                    width: 18px;
                    height: 18px;
                    border: 2px solid #ddd;
                    border-radius: 2px;
                    display: block;
                }

                svg {
                    width: 100%;
                }
            }

            .vps-text {

                float: left;
                width: auto;
                padding: 0 0 0 10px;
                font-size: 16px;
                line-height: 16px;
            }

            .vps-used-vitapoints {

                color: #FF0000;
                float: right;
                width: auto;
                font-size: 16px;
            }
        }

        .vitapoints-balance-text {
            width: 100%;
            float: left;
            font-size: 14px;
            color: #9B9B9B;
            padding: 0 0 0 28px;
        }
    }
}

.vita-pnts-image {
    position: relative;
    width: 100%;
    float: left;

    img {
        width: 25px;
        position: absolute;
        top: -20px;
        left: 0;
    }
}

.vita-pnts-redeemed {
    font-size: 14px;
    line-height: 1.5;
    // color: #ffffff;
    color: $blue;
    //  border-radius: 12px;
    padding: 1px 5px;
    //  background-color: #7dbe00;
}


@media screen and (max-width:767px) {
    .vita-points-container {
        padding-top: 30px;
        margin: 10px 0 0 0;
        border-top: 1px solid $grey-11;
    }

    .cart-sum-tblinner {
        border-radius: 0px;
        position: relative;
        background: unset;
        padding: 0px;
        width: 100%;
        float: left;
        line-height: 1.42;
    }

    .address-data .cart-sum-tblinner {
        padding: 0px;
    }

    .process-steps {
        position: relative;

        .col-xs-3 {
            width: 25%;
            float: left;
            position: relative;
        }

        .steps {
            background: #fff;
            padding: 15px 0 10px;
            margin: 0 0 10px;
            text-align: center;

            .number {
                padding: 0 6px;
                border: 1px solid #eee;
                background-color: #eee;
                border-radius: 12px;
                background: $grey-0;
            }

            &>div {
                margin-top: 10px;
                line-height: 20px;
            }

            &.complete {
                .number {
                    border-color: $orange4;
                    background: $orange4;
                    color: #fff;
                }

                &:after {
                    border-color: #7c7f80;
                }

            }

            &.current {
                .number {
                    border-color: #7c7f80;
                    background: #7c7f80;
                    color: #fff;
                }
            }

            &:after {
                border-top: 2px solid #ccc;
                content: "";
                display: inline-block;
                left: 65%;
                position: absolute;
                top: 25px;
                width: 70%;
                z-index: 2;
                border-color: $grey-0;
            }

            &:last-child:after {
                display: none;
            }
        }
    }

    .total-pay-summary {
        //display: inline-block;
        width: 100%;
        float: left;
        padding: 0 15px;
        border-bottom: 5px solid $grey-11;

        .vita-points-container {
            padding: 0;
            margin: 25px 0 0 0;
            border: none;
        }

        .--row {
            width: 100%;
            line-height: normal;
            float: left;
            display: flex;
            justify-content: space-between;

            .text-right {
                text-align: right;
            }
        }

        .total-payable {
            width: 100%;
            float: left;


        }

        .total-amount-payable {
            width: 100%;
            float: left;
            padding: 15px 0;

            .text-right span {

                font-size: 18px;
            }

            span {
                font-size: 14px;
                line-height: 22px;


            }
        }

        .you-save {
            text-align: right;
            line-height: 17px;

            span {
                color: $orange4;
                font-size: 12px;
                line-height: 17px;
            }
        }

        .promo-discount {
            margin-top: 5px;

            * {
                font-size: 12px;
                color: #929495;
            }
        }

        .shipping-charg {
            * {
                font-size: 12px;
                color: #929495;
            }
        }

        .hk-cash {
            * {
                font-size: 12px;
                color: #929495;
            }

            .txt-blue {
                color: #1ebcbd;
                margin-left: 4px;
            }
        }

        .total-hk-cash {
            margin: 10px 0 0px;
            padding-top: 0px;
            border-top: 0px solid #d3d4d5;
            display: inline-block;
            width: 100%;

            .loyality-text {
                color: $black2;
                border: 1px dotted #8ccd41;
                margin-left: 0px;
                padding: 6px 0px;
                text-align: center;
                font-size: 12px;
                line-height: normal;

                span {
                    color: #8ccd41;
                    font-weight: bold;
                    line-height: inherit;
                    color: inherit;
                    font-size: inherit;
                }
            }
        }
    }
}