@import '../global/global.scss';

.error-pagecont {
    margin: 40px auto;
    max-width: 1170px;
    width: 100%;
    .img-error {
        width: 49%;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
    }
    .content-error {
        width: 49%;
        display: inline-block;
        vertical-align: middle;
        h3 {
            margin-top:0;
            font-size: 28px;
            float: left;
            width: 100%;
            margin-bottom: 5px;
            color: #1a4f69;
            font-weight: 400;
            line-height: 40px;
        }
        p {
            font-size: 16px;
            float: left;
            width: 90%;
            margin-top:0;
            margin-bottom: 20px;
            line-height: 25px;
        }
        a {
            font-size: 18px;
            background: #00bfbf;
            padding: 8px 20px;
            color: #fff;
            float: left;
        }
    }
}

// media queries

@media only screen and (max-width: 760px) {
    .error-pagecont {
        float: left;
        width: 100%;
        padding: 20px 15px;
        box-sizing: border-box;
        .img-error {
            width: 100%;
            text-align: center;
            display: inline-block;
            img {
                width: 80%;
            }
        }
        .content-error {
            width: 100%;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            h3 {
                font-size: 22px;
                float: left;
                width: 100%;
                margin-bottom: 5px;
                color: #1a4f69;
                line-height: 30px;
                font-weight: bold;
            }
            p {
                font-size: 13px;
                float: left;
                width: 100%;
                margin-bottom: 20px;
                line-height: 20px;
            }
            a {
                font-size: 16px;
                background: #00bfbf;
                padding: 8px 20px;
                color: #fff;
                display: inline-block;
                float: none;
            }
        }
    }
}