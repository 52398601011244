@import '../../../../static/css/global/global.scss';

.header-cntnr-parent{
    width: 100%;
    background: #FFF;
    padding: 16px;
    box-shadow: 0 2px 10px rgba(113,122,134,.2);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .slh-left-cntnr{
        display: flex;
        align-items: center;
        gap: 15px;
        max-width:65%;

        .header-ttl{
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            color: #20262e;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 75%;
        }
    }

    .slh-right-cntnr{
        @extend .slh-left-cntnr;
        justify-content: flex-end;
        a{
            position: relative;
        }
        div{
            display: flex;
        }
        .menu-logo-container{
            a{
                height: 24px;
            }
        }
    }
}