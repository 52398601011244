@import '../../../../../../static/css/global/global.scss';

.SNoResults{
    &__container{
        max-width: 750px;
        margin: 0 auto;
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 767px){
            padding-top: 50px;
        }
    }

    &__img{
        margin-bottom: 14px;
        width: 100%
    }

    &__title{
        @include fontStyle($fontFamily-oswald, 26px, 500, 24px, 0, $color-primary);
        margin-bottom: 7px;
        @media (max-width: 767px){
            @include fontStyle($fontFamily-oswald, 20px, 500, 24px, 0, $color-primary);
            margin-bottom: 5px;
        }
    }

    &__small{
        @include fontStyle($fontFamily-default, 16px, 400, 22px, 0px, rgba($color-primary,.7));
    }
}