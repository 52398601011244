@import "static/css/global/global.scss";

.informationStrip {
    display: block;
    background: #272b65;

    p {
        font-family: $fontFamily-default;
        font-weight: 600;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        line-height: 26px;
        text-align: center;
        max-width: 1200px;
        margin: 0 auto;
        color: #FFF;
        padding-top: 2px;
        padding-bottom: 4px;
    }

    marquee {
        margin-top: 5.6px;
        font-family: $fontFamily-default;
        font-weight: 600;
        line-height: 18px;
        font-size: 13px;
        color: #FFF;
    }

    @media screen and (max-width:767px) {
        padding: 0 6px;

        p {
            line-height: 22px;
        }

        marquee {
            line-height: 14px;
            margin-top: 5.6px;
        }
    }
}