@import '../../../../static/css/global/global.scss';

.header-mweb{
    &__outer-container{
        position: relative;

        .search-box__parent{
            position: absolute;
            top: 0;
            transition: opacity .5s ease, visibility .5s ease;
        }
    }

    &__container{
       // background-color: $color-primary;
       background-color:#FFF !important;
        height: 65px;
        
        .HK-pagecontainer{
            height: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            padding: 21px 20px;

            @media screen and (max-width: 1024px) {
                box-shadow: 0px 3px 10px #717A864D;
            }
            .menu-and-logo-container{
                display: flex;
                align-items: center;
                div{
                    display: flex;
                    height: min-content;
                }
               .header-mweb__logo{
                margin-left: 10px;
                border-left: 1px solid #E8E9EA;
                padding-left: 10px;
               }
            }

            .menu-search-container{
                width: 100%;
                padding: 0;
                // background-color: #E8E9EA;
                border-radius: 4px;
                display: flex;
                align-items: center;
                // margin-top: 8px;
                .search-icon-data{

                }
                .menu-searchinput-box{
                    width: 100%;
                    margin-left: 8px;
                    @include fontStyle($fontFamily-default, 14px, 500, 24px, 0px,  #414C58);
                    border: none;     
                    background-color: #E8E9EA;

                }

                .loyalty-icon-logged-in{
                    position: absolute;
                    top: -8px;
                    right: -8px;
                }
            }
        }
    }

    &__right{
        display: flex;
        align-items: center;
        column-gap: 15px;
        .mbcash{
            position: relative;
            padding: 0px 6px;
            .amount{
                color: #080A08;
                background: #FDE100;
                position: absolute;
                top: -17px;
                left: 17px;
                font-size: 10px;
                line-height: normal;
                padding: 1px 5px;
                border-radius: 4px;
                margin-top: 5px;
                font-weight: 600;
                line-height: 17px;
                @media screen and (max-width: 1024px) {
                    bottom: -6px;
                    top: auto;
                    font-size: 9px;
                    font-weight: 24px;
                    color: #FFFFFF;
                    background: #51B279;
                    border-radius: 8px;
                    margin-top: 0px;
                    padding: 2px 5px ;
                }
            }
        }
        
        // img.humburger{
        //     margin-left: 10px;
        // }
    }
}

.remove-header{
    visibility: hidden;
}

.close-btn-search{
    position: absolute;
    top: 25px;
    right: 15px;
    z-index: 999999;
}