@import "static/css/global/global.scss";

.review-header {
    padding: 12px 16px;
    display: flex;
    .logo {
        margin-right: 10px;

        img {
            width        : 50px;
            height       : 50px;
            border-radius: 50%;
            display      : inline-block;
        }
    }

    .product_name {
        width        : 100%;
        margin-bottom: 4px;
        position     : relative;
        display      : flex;

        a {
            display: block;
        }


        div {
            font-size  : 18px;
            line-height: 21px;
            font-family: $fontFamily-oswald;
            color      : $color-primary;
            @include lineClamp(1);
            padding-right: 36px;
        }

        .vegnonveg {
            position: absolute;
            top     : 0;
            right   : 0;
        }
    }

    .quantity-selector {
        margin-right: 10px;

        @media screen and (max-width: 1024px) {
            margin-top: 14px
        }
    }

    .price-wrapper {
        .actual-price {
            font-size      : 20px;
            font-weight    : 500;
            line-height    : 18px;
            text-decoration: line-through;
            color          : $color-primary;
            opacity        : 0.4;
            margin-right   : 8px;
            font-family    : $fontFamily-default;
        }

        .offer-price {
            font-size  : 20px;
            font-weight: 700;
            line-height: 18px;
            color      : $color-primary;
            font-family: $fontFamily-default;
        }
    }

    .quantitySelector {
        margin-top   : 14px;
        border-bottom: none;
    }

    .cta-btns {
        width          : 50%;
        display        : flex;
        flex-direction : column;
        justify-content: space-between;

        .hk-elements--addToCart {
            width        : 100%;
            margin-bottom: 20px;

            button {
                width        : 100%;
                border       : none;
                outline      : none;
                background   : #001D42;
                border-radius: 5px;
                height       : 40px;
                @include fontStyle($fontFamily-default, 12px, $medium, 17px, 0px, #FFFFFF);
            }
        }

        .hk-elements--buyNow {
            width: 100%;

            button {
                width        : 100%;
                border       : none;
                outline      : none;
                background   : #FFD800;
                border-radius: 5px;
                height       : 40px;
                @include fontStyle($fontFamily-default, 12px, $medium, 17px, 0px, #001D42);
            }
        }

        &.fixed-cta {
            width          : 100%;
            position       : fixed;
            bottom         : 0;
            left           : 0;
            right          : 0;
            background     : #FFFFFF;
            filter         : drop-shadow(0 3px 6px #00000029);
            flex-direction : row-reverse;
            justify-content: space-between;
            padding        : 20px 0;
            z-index        : 999;

            .hk-elements--addToCart {
                width       : 50%;
                margin      : 0;
                text-align  : left;
                padding-left: 5px;

                button {
                    max-width: 156px;
                }
            }

            .hk-elements--buyNow {
                width        : 50%;
                margin       : 0;
                text-align   : right;
                padding-right: 5px;

                button {
                    max-width: 156px;
                }
            }
        }
    }
}