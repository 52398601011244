@import 'static/css/global/global.scss';
@import 'static/css/checkout/checkout.scss';

.hk-loader-default {
    position: fixed;
    background: rgb(238, 238, 238);
    opacity: 0.5;
    width: 100%;
    height: 100%;
}
.tbs-coupon-code-input-container {
    width: 100%;
    position: relative;
    .tbs-coupon-code-input-label {
        position: absolute;
        left: 0;
        bottom: 25px;
        width: inherit;
        color: #9B9B9B;
        z-index: -1;
    }
    .tbs-coupon-code-input {
        width: 60%;
        float: left;
        height: 40px;
        position: relative;
        left: 0;
        bottom: 0;
        border: none;
        outline: none;
        border-bottom: 1px solid #DDDDDD;
        background: transparent;
    }
    &.is-focused {
        .tbs-coupon-code-input-label {
            display: none;
            top: -12px;
            font-size: 12px;
            color: $primary-color;
            line-height: 14px;
        }
        .tbs-coupon-code-input {
            border-bottom: 1px solid $primary-color;
        }
    }
    .login-cpn {
        color: #9B9B9B;
        font-size: 14px;
        line-height: 1;
        padding: 0 15px;
        text-align: center;
        span {
            cursor: pointer;
            color: $primary-color;
            font-size: inherit;
            border-bottom: 1px solid $primary-color;
        }
    }
}
.freebie-item {
    span{
        font-weight: 500;
    }
    padding    : 10px 20px;
    font-weight: 500;
    font-size  : 14px;
    line-height: 20px;
    color      : #56c749;
    clear      : both;
}
.tbs-cart-page-section {
    width: 100%;
    float: left;
    padding: 0 15px;
    background: $white;
    &.no-border {
        border-bottom: unset;
    }
    &.offer-sec {
        padding: 0px
    }
    .tbs-cart-page-section-items-count {
        display: inline-block;
        margin: 0 0 0 10px;
        font-size: 16px;
        color: #9B9B9B;
        vertical-align: middle;
    }
}
.cart-layout {
    width: 100%;
    float: left;
    .cart-sum-tbl{
        position: absolute;
        top: 65%;
        top: calc(97vh - 190px)!important;
        width: 168px;

        @media screen and (min-width: 1024px) {
            top: calc(97vh - 210px)!important;
        }
    }
    .save-your-cart {
        float: left;
        width: 100%;
        min-height: 50px;
        border: 1px solid rgb(255, 248, 248);
        border-radius: 3px;
        padding: 15px 10px;
        margin-top: 35px;
        margin-bottom: 10px;
        .save-your-cart-img {
            width: 35%;
            height: auto;
            float: left;
            padding-right: 15px;
            box-sizing: border-box;
            text-align: center;
            img {
                vertical-align: middle;
            }
        }
        .save-your-cart-text {
            float: left;
            width: 65%;
            p {
                font-weight: 400;
                color: #25292b;
            }
            ul {
                margin: 0px;
                padding: 0px;
                list-style: none;
                margin-top: 15px;
                li:first-child {
                    border-right: 1px solid #ccc;
                    margin-left: -10px;
                }
                li {
                    float: left;
                    line-height: 20px;
                    a {
                        padding: 0px 15px;
                        text-decoration: none;
                        font-weight: 600 !important;
                        color: #177a99 !important;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    .trust-cont {
        margin-top: -5px;
    }
    .row {
        margin-left: -15px;
        margin-right: -15px;
    }
    .tbl-ttl {
        @include headingStyle();
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        text-align: left;
        padding: 0;
        margin: 40px 0 20px 0;
        font-size: 24px;
        #cartitemcounthead {
            display: inline-block;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
        }
        &.order-summary-heading {
            font-size: 18px;
            color: #9B9B9B;
            text-transform: uppercase;
            margin-top: 20px;
        }
    }
    .box-shdw-none {
        width: 100%;
        float: left;
        .cart-prdct-tbl {
            width: 100%;
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            float: left;
            line-height: 25px;

            .tbs-cart-page-section{

                .plus_icon .plus_image{
                    margin-top: 26px !important;
                }
                .cartitem-list{
                    padding: 21px 0 !important;
                }
            }
            .tbs-cart-page-section .cart-pack-item .cartPackChild .combo-header{
                display: none !important;
            }
            &.page-layout {
                width: 66.66666667%;
            }
            .wishlist--cart--msg-top {
                position: absolute;
                right: 16px;
                top: 20px;
                color: #1a4f69;
                font-size: 13px;
                font-weight: 600;
                display: block;
                text-decoration: underline;
                margin-top: -10px;
                line-height: 18px;
                cursor: pointer;
            }
            .line-item {
                width: 100%;
                float: left;
                padding: 30px 0 30px 0;
                transition: all 1s linear;
                opacity: .9;
                &.bxgy {
                    border: 0;
                    margin-bottom: 0;
                    .variant-desc .wishlist--cart--item-msg a:last-child {
                        border: 0;
                        padding: 0;
                    }
                }
                &>.row {
                    margin-left: 0;
                    margin-right: 0;
                    padding-bottom: 15px;
                    display: inline-block;
                    width: 100%;
                }
                .prod-img {
                    float: left;
                    position: relative;
                    min-height: 1px;
                    width: 15%;
                    border-radius: 4px;
                    text-align: center;
                    a {
                        img {
                            display: block;
                            margin: auto;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .variant-desc {
                    width: 85%;
                    float: left;
                    position: relative;
                    min-height: 1px;
                    padding-left: 15px;
                    padding-right: 15px;
                    .row {
                        margin: 0;
                    }
                    .variant-name {
                        width: 100%;
                        float: left;
                        span.pack-item-qty.no-wrap {
                            font-size: 12px;
                            color: #929495;
                            white-space: nowrap;
                        }
                        span.pack-item-qty.no-wrap * {
                            color: inherit;
                            font-size: inherit;
                        }
                    }
                    .variant-quantity {
                        width: 100%;
                        float: left;
                        font-size: 12px;
                        color: #9B9B9B;
                        margin-bottom: 16px;
                    }
                    .cart-dosage-summary {
                        width: 100%;
                        float: left;
                        margin: 5px 0;
                        .dosage-quantity {
                            font-size: 14px;
                            line-height: 1.71;
                            color: #21a0b0;
                            padding: 0 20px 0 0;
                        }
                        .dosage-price {
                            font-size: 14px;
                            line-height: 1.71;
                            color: #21a0b0;
                        }
                    }
                    p.loyality-text {
                        color: $black2;
                        padding-left: 0;
                        background-size: 20px;
                        background-repeat: no-repeat;
                        background-position: 13px 0;
                        font-weight: 400;
                        float: left;
                        width: 100%;
                        line-height: 20px;
                        margin-top: 5px;
                        .singleLoyaltyCash {
                            font-weight: 600;
                            color: $orange4;
                        }
                        &.free {
                            color: red;
                        }
                    }
                    .wishlist--cart--item-msg {
                        display: block;
                        position: relative;
                        z-index: 1;
                        float: right;
                        margin-top: 20px;
                        margin-right: -10px !important;
                        a {
                            color: #929495;
                            font-size: 13px;
                            display: inline-block;
                            margin-right: 8px;
                            display: inline-block;
                            font-weight: 600;
                            line-height: 20px;
                        }
                    }
                }
                .bxgy-off {
                    padding: 0;
                    box-sizing: border-box;
                    margin: 0px 0px 0px;
                    padding: 5px 0;
                    float: left;
                    width: 100%;

                    .cart-freebie {
                        display: flex;
                        align-items: center;
                        border: 1px solid #C7E3B8;
                        background-color: #F4FFFA;
                        padding: 12px 8px;
                        margin-top: 8px;
                        .green-txt {
                            color: #00A358;
                            font-weight: 500;
                            line-height: 18px;
                        }

                        .offer-applied {
                            @include text-overflow($lineCount: 1);
                            line-height: 18px;
                            font-weight: 500;
                            font-size: 14px !important; 
                        }

                        .freebie-qty {
                            font-size: 12px;
                            line-height: 22px;
                            color: #1A1A1A;
                            opacity: 0.8;
                        }   
                    }

                    img.freeBIcon {
                        background: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/offer.svg);
                        width: auto;
                        height: 45px;
                        margin-right: 10px;
                        float: left;
                        &.freebie-green {
                            background: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/freebiIcon-v1.svg) no-repeat;
                        }
                    }
                    a {
                        font-size: 12px;
                        font-weight: 400;
                        color: #25292b;
                        text-decoration: underline;
                        line-height: 1.8;
                    }
                    a.offer-applied {
                        color: #000;
                        font-weight: 700;
                        text-decoration: none;
                    }
                }
            }
            .couponStatus {
                width: 100%;
                float: left;
                padding: 0px;
                margin: 0 0 20px 0;
                div[data-role="message"] {
                    color: $primary-color;
                    font-size: 14px;
                    line-height: 2;
                    font-weight: 600;
                    position: relative;
                    top: 1px;
                    display: inline-block;
                }
                .mrgn-t-5 {
                    font-size: 11px;
                    margin-top: 5px;
                    line-height: 15px;
                }
                .icn-success {
                    background: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/confirm-47.svg) center no-repeat;
                    width: 25px;
                    height: 25px;
                    display: inline-block;
                    position: relative;
                    top: 3px;
                    vertical-align: top;
                    margin-right: 4px;
                    &.freebie {
                        background: url(https://static1.hkrtcdn.com/gritzo/static/media/pdp/freebiIcon-v2.svg)
                    }
                }
            }
        }
        .cart-sum-tbl {
            width: 40%;
            float: left;
            position: relative;
            min-height: 1px;
            padding-left: 80px;
            // padding-right: 15px;
            .cart-auth-sec {
                margin-bottom: 20px;
                padding-bottom: 20px;
                float: left;
                margin-top: 30px;
                border-bottom: 1px solid #d3d4d5;
                width: 100%;
                div {
                    display: block;
                    padding-left: 40px;
                    margin-bottom: 10px;
                    color: $green;
                    font-weight: 600;
                }
                .authenticIcon {
                    background: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/auth_new.gif);
                    background-size: 30px;
                    min-height: 30px;
                    line-height: 30px;
                    background-repeat: no-repeat;
                }
                .securepayment {
                    background: url(https://static1.hkrtcdn.com/gritzo/static/media/truebasics/security-icon.svg);
                    background-size: 30px;
                    min-height: 30px;
                    line-height: 30px;
                    background-repeat: no-repeat;
                    font-size: 16px;
                }
            }
            .payment-type {
                float: left;
                width: 100%;
                padding-bottom: 20px;
                border-bottom: 1px solid #d3d4d5;
                h4 {
                    margin: 10px 0px;
                    text-transform: capitalize;
                    @include headingStyle();
                    // font-weight: 600;
                }
            }
            .apply-offer-container {
                width: 100%;
                float: left;
                .offerouter {
                    width: 100%;
                    padding: 21px 21px 41px 21px;
                    border: 1px solid #F2F2F2;
                    margin-bottom: 30px;
                    border-radius: 5px;
                    position: relative;
                    &.applied-cnt {
                        padding: 14px 21px 15px 21px;
                    }
                    .coupn-container {
                        &>svg {
                            position: relative;
                            top: 15px;
                        }
                        .apply-coupon {
                            background: #ffffff;
                            border-radius: 20px;
                            box-shadow: none;
                            height: 40px;
                            line-height: 40px;
                            padding: 0;
                            display: inline-block;
                            width: 93px;
                            text-align: center;
                            margin-left: 10px;
                            color: $grey-12;
                            border: 1px solid $grey-12;
                            &:not(.disabled) {
                                color: $primary-color;
                                border: 1px solid $primary-color;
                            }
                            &.apply-coupon-enable {
                                background: #21a0b0;
                                background: $primary-color;
                                color: #fff;
                            }
                        }
                        .tbs-coupon-code-input-container {
                            width: 100%;
                            position: relative;
                            height: 40px;
                            margin: 10px 0 0 0;
                            .tbs-coupon-code-input-label {
                                position: absolute;
                                left: 0;
                                bottom: 20px;
                                width: inherit;
                                color: #9B9B9B;
                                z-index: -1;
                            }
                            .tbs-coupon-code-input {
                                left: 0;
                                bottom: 0;
                                border: none;
                                outline: none;
                                padding: unset;
                                border-radius: unset;
                                height: 40px;
                                border-bottom: 1px solid #DDDDDD;
                                background: transparent;
                            }
                            &.is-focused {
                                .tbs-coupon-code-input-label {
                                    top: -12px;
                                    font-size: 12px;
                                    color: $primary-color;
                                    line-height: 14px;
                                }
                                .tbs-coupon-code-input {
                                    border-bottom: 1px solid $primary-color;
                                }
                            }
                            .err-txt {
                                font-size: 12px !important;
                                &.active {
                                    position: absolute;
                                }
                            }
                        }
                        .offer-container-wrapper {
                            width: 100%;
                            float: left;
                            margin: 10px 0;
                            svg {
                                float: left;
                                width: auto;
                            }
                        }
                        .couponright {
                            margin: 6px 0 0;
                            display: inline-block;
                            width: 92%;
                            vertical-align: middle;
                            padding: 0 0 0 13px;
                            .remove-cpn {
                                width: 100%;
                                float: left;
                                span {
                                    width: auto;
                                    float: left;
                                    font-weight: 600;
                                    span {
                                        width: auto;
                                        float: right;
                                        padding: 0 0 0 5px;
                                    }
                                }
                            }
                            .btn {
                                text-transform: capitalize;
                                cursor: pointer;
                                color: $primary-color;
                                text-shadow: none;
                                box-shadow: none;
                                border: 1px solid #d3d4d5;
                                display: block;
                                text-align: center;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .btn.disp-inln {
                                display: inline-block;
                            }
                            .btn.btn-gray {
                                border-color: #d3d4d5;
                                color: #25292b;
                            }
                            .btn.btn-mini {
                                //padding: 0 6px;
                                font-size: 14px;
                                line-height: 24px;
                                background: #ffffff;
                                border: none;
                                text-transform: capitalize;
                                text-decoration: underline;
                                color: $primary-color;
                                width: auto;
                                float: right;
                            }
                            .coupn-apply--widget {
                                .apply-coupon {
                                    background: #ffffff;
                                    border-radius: 20px;
                                    box-shadow: none;
                                    height: 40px;
                                    line-height: 40px;
                                    padding: 0;
                                    display: inline-block;
                                    width: 93px;
                                    text-align: center;
                                    margin-left: 10px;
                                    color: $grey-12;
                                    border: 1px solid $grey-12;
                                    &:not(.disabled) {
                                        color: $primary-color;
                                        border: 1px solid $primary-color;
                                    }
                                    &.apply-coupon-enable {
                                        background: #21a0b0;
                                        background: $primary-color;
                                        color: #fff;
                                    }
                                }
                                .tbs-coupon-code-input-container {
                                    width: 100%;
                                    position: relative;
                                    .tbs-coupon-code-input-label {
                                        position: absolute;
                                        left: 0;
                                        bottom: 9px;
                                        width: inherit;
                                        color: #9B9B9B;
                                        z-index: -1;
                                    }
                                    .tbs-coupon-code-input {
                                        left: 0;
                                        bottom: 0;
                                        border: none;
                                        outline: none;
                                        padding: unset;
                                        border-radius: unset;
                                        height: 40px;
                                        border-bottom: 1px solid #DDDDDD;
                                        background: transparent;
                                    }
                                    &.is-focused {
                                        .tbs-coupon-code-input-label {
                                            top: -12px;
                                            font-size: 12px;
                                            color: $primary-color;
                                            line-height: 14px;
                                        }
                                        .tbs-coupon-code-input {
                                            border-bottom: 1px solid $primary-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .prompt-offers-cnt {
                        padding: 0;
                        .offer-item {
                            padding: 10px 15px;
                        }
                    }
                }
                .offertitle {
                    width: 100%;
                    float: left;
                    font-size: 16px;
                    width: calc(100% - 50px) !important;
                    margin: 0 0 0 10px;
                    color: unset;
                    height: auto;
                    line-height: unset;
                }
            }
        }
    }
}

// wishlist popup
.wishlist--cart-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .9);
    z-index: 99999;
    .no-vrnt {
        text-align: center;
    }
    .no-vrnt img {
        max-width: 200px;
        width: 100%;
    }
    .no-vrnt p {
        font-weight: 600;
        margin-top: 20px;
        font-size: 16px;
    }
    .wishlist--cart-popup-content {
        position: relative;
        display: block;
        background: #FFF;
        width: 65%;
        border-radius: 2px;
        padding: 25px;
        margin: 0 auto;
        margin-top: 5%;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 550px;
        min-height: 400px;
        -webkit-overflow-scrolling: touch;
        .hk-elements--addToCart button {
            display: block;
            margin-top: 15px;
            padding: 8px !important;
            font-size: 15px !important;
            border-radius: 2px;
            width: 90%;
            border: 1px solid #000;
            border-color: #df4723;
            border-top-color: #df4723;
            border-left-color: #df4723;
            background-color: #df4723;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
        }
        .wishlist--cart-popup-item--price {
            display: block;
            margin-top: 5px;
            .wl-price-main {
                font-weight: 700;
                font-size: 16px;
            }
            .wl-price-strike {
                margin-left: 5px;
                font-size: 15px;
                color: #929495;
                text-decoration: line-through;
            }
            .wl-price-discount {
                margin-left: 5px;
                font-size: 14px;
                text-transform: capitalize;
                color: #df4723;
                font-weight: 600;
                border: 1px solid #df4723;
                border-radius: 2px;
                padding: 0 7px;
            }
        }
        .price-hike {
            display: block;
            margin-top: 8px;
            img {
                margin-right: 4px;
                vertical-align: middle;
                height: 12px;
                margin-top: -2px;
            }
            span {
                font-weight: 600;
                font-size: 12px;
                vertical-align: top;
            }
            .was-before {
                font-weight: 400 !important;
                display: block;
            }
        }
        .rating-cont {
            margin-top: 5px;
            display: inline-block;
            .rtng-star {
                position: relative;
                display: inline-block;
                width: 70px;
                height: 14px;
                margin-top: 4px;
                margin-right: 8px;
                text-align: left;
                background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/cart/star-empty.svg);
                background-repeat: repeat-x;
                .rtng-usr {
                    display: inline-block;
                    height: 14px;
                    background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/cart/star-filled.svg);
                    background-repeat: repeat-x;
                    vertical-align: top;
                }
            }
            .rating-wrapper {
                float: left;
            }
            .review-count {
                float: left;
                font-size: 13px;
                color: #7c7f80;
            }
        }
    }
    .wishlist--cart-popup-content h3 {
        
        font-size: 22px;
        text-align: center;
        text-transform: capitalize;
        font-weight: 400;
    }
    .wishlist--cart-popup-close {
        position: absolute;
        width: 15px;
        height: 15px;
        right: 15px;
        top: 10px;
        cursor: pointer;
    }
    @media screen and (min-width:1024px) {
        .tbs-coupon-code-input-container {
            width: 100%;
            float: left;
            .tbs-coupon-code-input-label {
                position: absolute;
                left: 0;
                bottom: 25px;
                width: inherit;
                color: #9B9B9B;
                z-index: -1;
            }
            .tbs-coupon-code-input {
                width: 60%;
                float: left;
                height: 40px;
                border: 1px solid #DDDDDD;
            }
            &.is-focused {
                .tbs-coupon-code-input-label {
                    top: -12px;
                    font-size: 12px;
                    color: $primary-color;
                    line-height: 14px;
                }
                .tbs-coupon-code-input {
                    border-bottom: 1px solid $primary-color;
                }
            }
        }
        .wishlist--cart-popup-content::-webkit-scrollbar {
            width: 6px;
        }
        .wishlist--cart-popup-content::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #888;
        }
        .wishlist--cart-popup-content::-webkit-scrollbar-track {
            border-radius: 5px;
            background: #f1f1f1;
        }
    }
    .wishlist--cart-popup-items {
        margin-top: 20px;
    }
    .wishlist--cart-popup-item {
        width: 32.9%;
        display: inline-block;
        margin: 0;
        margin-top: 10px;
        padding: 10px 30px;
        vertical-align: top;
    }
}
@media screen and (max-width:1024px) {
    .cart-layout {
        .no-data-container {
            margin-top: 13px;
            padding: 0 15px;
            text-align: center;
            .cart-icon {
                max-width: 171px;
                margin-top: 70px;
            }
            .cart-back-shop {
                width: 100%;
            }
        }
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .couponStatus {
        padding: 0;
    }
    .cart-layout .order-summary-widget .order-summary-heading {
        margin: 15px 0 5px 0;
        font-size: 16px;
    }
    .tbs-cart-page-section {
        .tbs-cart-page-section-items-count {
            font-size: 12px;
        }
        .cart-sum-tbl {
            .order-summary-widget {
                width: 100%;
                float: left;
                * {
                    color: #100E09;
                }
                .ptc-text,
                .ptc-cart-value {
                    color: #fff;
                }
                .col-sm-6-mb-0 {
                    .total-payable {
                        font-size: 21px;
                    }
                }
                .cart-sum-tblinner {
                    .cart-summary {
                        .order-summary {
                            padding: 0;
                        }
                    }
                }
            }
        }
        :nth-child(2) {
            border-top: none;
        }
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl {
        width: 100%;
        float: none;
        padding: 0;
        margin-bottom: 0;
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .line-item .prod-img {
        width: 100px;
        border: none;
        border-radius: unset;
        a {
            width: 100%;
            float: left;
            img {
                width: 100%;
            }
        }
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .line-item .row {
        padding: 0;
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .line-item .variant-desc {
        width: calc(100% - 100px);
        .variant-name {
            margin-bottom: 6px;
        }
        p.loyality-text {
            color: $black2;
            font-size: 13px;
            line-height: 20px;
            span {
                line-height: inherit;
                font-size: inherit;
            }
        }
        .wishlist--cart--item-msg {
            float: left;
            margin: 0;
            width: 100%;
        }
        .row {
            .variant-quantity {
                margin: 0;
                line-height: 14px;
                margin-bottom: 16px;
            }
        }
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .apply-offer-container .offerouter .coupn-container .offer-container-wrapper {
        svg {
            float: left;
        }
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .apply-offer-container .offerouter .coupn-container .couponright .coupn-apply--widget {
        .apply-coupon {
            background: #ffffff;
            border-radius: 20px;
            box-shadow: none;
            height: 40px;
            line-height: 40px;
            padding: 0;
            display: inline-block;
            width: 93px;
            text-align: center;
            margin-left: 15px;
            color: $grey-12;
            border: 1px solid $grey-12;
            &:not(.disabled) {
                color: $primary-color;
                border: 1px solid $primary-color;
            }
        }
        .tbs-coupon-code-input-container {
            width: 100%;
            float: left;
            position: relative;
            height: 60px;
            margin: 10px 0 0 0;
            .tbs-coupon-code-input-label {
                position: absolute;
                left: 0;
                bottom: 25px;
                width: inherit;
                color: #9B9B9B;
                z-index: -1;
                width: 66%;
            }
            .tbs-coupon-code-input {
                left: 0;
                bottom: 0;
                border: none;
                outline: none;
                padding: unset;
                border-radius: unset;
                height: 40px;
                border-bottom: 1px solid #DDDDDD;
                background: transparent;
                width: 68%;
            }
            &.is-focused {
                .tbs-coupon-code-input-label {
                    top: -12px;
                    font-size: 12px;
                    color: $primary-color;
                    line-height: 14px;
                }
                .tbs-coupon-code-input {
                    border-bottom: 1px solid $primary-color;
                }
            }
        }
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .apply-offer-container .offerouter .coupn-container {
        width: 100%;
        padding: 15px;
        background: none;
        padding: unset !important;
        float: left;
        border-radius: 4px;
        padding: 10px;
        .offer-container-wrapper {
            margin-bottom: 10px;
            float: left;
            width: 100%;
        }
        .couponright {
            margin: 0;
            padding: 0;
            width: 100%;
            .js-cpn-applied {
                font-weight: 600;
            }
            .discount {
                color: #9B9B9B;
            }
            .remove-coupon {
                float: right;
                color: $primary-color;
                border-bottom: 1px solid $primary-color;
                font-weight: 600;
                line-height: 13px;
            }
        }
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .apply-offer-container .offerouter .prompt-offers-cnt {
        padding: 0;
        .offer-item {
            padding: 10px 15px;
        }
    }
    .cart-layout .box-shdw-none .cart-sum-tbl {
        float: none;
        width: 100%;
        display: inline-block;
        padding: 0;
    }
    .order-summary-widget .cart-sum-tblinner {
        box-shadow: none;
        border: none;
        &:after {
            display: none;
        }
        .cart-summary {
            padding: 0;
            .payrow {
                display: none;
            }
        }
        .total {
            font-size: 14px;
        }
        .yousave {
            color: #00C853;
            font-size: 12px;
            position: relative;
            text-align: right;
            box-sizing: border-box;
            line-height: 17px;
            span {
                font-size: 12px;
                color: #00C853;
            }
            * {
                color: #00C853;
            }
        }
        .incl-tx-txt {
            padding: 10px;
        }
    }
    .clear-fix {
        display: inline-block;
        width: 100%;
        &.amt-paybl {
            .col-sm-6 {
                margin-bottom: 0;
            }
            * {
                font-weight: 600;
                font-size: 16px;
            }
            .text-right * {
                color: #df4723;
                font-size: 16px;
            }
        }
    }
    .order-summary-widget .col-sm-6 {
        margin: 0px 0px 15px 0px;
        padding-left: 0;
        padding-right: 0;
        font-size: 13px;
        line-height: 18px;
        span {
            line-height: inherit;
            font-size: inherit;
        }
    }
 
    .cart-layout .box-shdw-none .cart-prdct-tbl .wishlist--cart--msg-top {
        right: 0;
    }
    .cart-layout .tbl-ttl {
        font-size: 18px;
        margin: 0;
    }
    .wishlist--cart-popup {
        .hk-loader-default {
            position: absolute;
        }
        .no-vrnt {
            text-align: center;
            width: 100%;
            max-width: 320px;
            padding: 10px;
        }
        .wishlist--cart-popup-content {
            position: absolute;
            display: block;
            background: #FFF;
            width: 100%;
            border-radius: 0;
            padding: 5px;
            overflow-x: auto;
            overflow-y: hidden;
            height: 430px;
            padding-top: 45px;
            bottom: 0;
            left: 0;
            animation: wishlist-popup-mobile-animation .4s ease-in-out;
            @keyframes wishlist-popup-mobile-animation {
                0% {
                    bottom: -500px;
                }
                100% {
                    bottom: 0;
                }
            }
            h3 {
                font-size: 22px;
                text-align: center;
                text-transform: capitalize;
                position: fixed;
                left: 0;
                right: 0;
                bottom: 385px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                display: block;
            }
            .wishlist--cart-popup-close {
                position: fixed;
                width: 15px;
                height: 15px;
                padding-top: 2px;
                cursor: pointer;
                left: 90%;
                bottom: 410px;
                top: auto;
            }
        }
        .wishlist--cart-popup-items {
            margin-top: 0;
            width: 1220px;
            overflow-x: scroll;
            min-width: 220px;
        }
        .wishlist--cart-popup-item {
            width: 220px;
            display: inline-block;
            margin: 0;
            margin-top: 10px;
            padding: 10px;
            vertical-align: top;
            margin-right: 10px;
        }
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .apply-offer-container .offertitle {
        width: 100%;
        float: left;
        font-size: 16px;
        width: calc(100% - 50px) !important;
        margin: 0 0 0 10px;
        color: unset;
        height: auto;
        line-height: unset;
    }
}
@media screen and (max-width: 768px) {
    .tbasics-page-layout-mob {
        .cart-layout {
            .box-shdw-none {
                .cart-prdct-tbl {
                    .line-item {
                        padding: 27px 0 30px 0;
                        &:last-child {
                            padding-bottom: 40px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 374px) {
    .cart-layout .box-shdw-none .cart-prdct-tbl .line-item .variant-desc .price>div:first-child {
        font-size: 16px;
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .line-item .variant-desc .price>div span {
        font-size: 16px;
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .line-item .variant-desc {
        padding-left: 10px;
        padding-right: 0px;
        .stepper {
            margin: 5px 7px 5px 0;
            width: auto;
            #minus,
            #plus,
            input {
                width: 20px;
                height: 20px;
                pointer-events: none;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
            input {
                pointer-events: none;
            }
        }
    }
    .cart-layout .box-shdw-none .cart-prdct-tbl .apply-offer-container .offerouter .coupn-container .couponright .coupn-apply--widget .apply-coupon {
        width: 77px;
    }
}
.cart-footer-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background: $white;
    transition: all 0.5s ease;
    padding: 0 20px;
    box-shadow: 0 -3px 6px $grey-35;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .price {
        font-size: 20px;
        color: $black;
        font-family: $fontRoboto;
    }
    .view-detail {
        color: $black;
        text-decoration: underline;
        font-size: 14px;
    }
}
.cart-prdct-tbl {
    float: left;
    width: 100%;
}
.cart-location {
    background: $grey-50;
}
.cart-coupon-section {
    background: $grey-15;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    &.text-section {
        margin-bottom: 0;
    }
    .coupon-wrap {
        display: flex;
        align-items: center;
        .text {
            margin-right: 10px;
            color: $black;
            font-size: 14px;
            line-height: normal;
            white-space: nowrap;
        }
        .form-group {
            margin: 0;
            .form-control {
                padding-right: 20px;
                height: 32px;
                line-height: 60px;
            }
            .apply-arrow {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
        }
    }.disp-inln{
        font-weight: 600;
        cursor: pointer;
    }
    .terms-modal-container{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        background: rgba(0,0,0,.6);
        .terms-modal-body {
            @media screen and (max-width: 1024px) 
                {
                width: 318px;
                left: 50%;
            }
            background: white;
            position: absolute;
            left: 46%;
            top: 50%;
            transform: translate(-50%, -50%);

            .terms-modal-content{
                padding: 20px;
                .heading-text{
                    font-weight: 600;
                }
            }
            .termsCross{
                position: absolute;
                top: 11px;
                right: 11px;
                cursor: pointer;
                width: 21px;
                padding: 5px;
            }
        }
    }
}

.gz-cart-menu-actions{
    // position: absolute;
    // bottom: -50px;
    // left: 0;
    width: 100%;
    float: left;
    // padding: 20px 0;
    text-align: center;
    margin: 50px 0 0 0;
    .gcma-cart-btn{
        width: 100%;
        display: inline-block;
        font-weight: 600;
        font-size: 16px;
        padding: 10px 20px;
        transition: .8s;
        border-radius: 12px;
        color: #fff;
        background: #c22c1f;
        cursor: pointer;
        &:hover{
            color: #c22c1f;
            background: #fff;
            transition: .8s;
        }
    }
    .gcma-close-btn {
        width: 100%;
        display: inline-block;
        font-weight: 600;
        font-size: 16px;
        padding: 10px 20px;
        transition: .8s;
        border-radius: 12px;
        color: #fff;
        background: #c22c1f;
        margin: 10px 0 0 0;
        cursor: pointer;
    }
}
