@import 'static/css/global/global.scss';

.right-menu-item {
    margin-left: 10px;
    cursor: pointer;
    display: inline-block;
}

.header-container-background-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    // background: #2D384499;
    opacity: .6;
    z-index: 9;
    top: 0px;
    bottom: 0px;
    visibility: hidden;

    &.display-menu {
        visibility: visible !important;
        opacity: 1 !important;
        left: 0 !important;
    }

}

.active-profile {
    width: 100% !important;
    visibility: visible;
    border-radius: 0px 0px 0px 0px !important;

    .menu-item {
        @media screen and (max-width: 1024px) {
            position: relative;
        }
        .header-container {
            border-radius: 0px 0px 0px 0px !important;
            background-image: url('../../../public/static/media/common/menu/profile-header-background.png') !important;
            min-height: 344px;
            // display: contents  !important;
            flex-direction: column;
        }

        .header-container-profile {
            padding: 48px 16px !important;
            align-items: flex-start !important;
            flex-wrap: wrap;

            .header-container-profile-image-section {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                position: relative;

                .header-container-profile-image{
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    height: 130px;
                    width: 130px;
                }

                
                .header-container-profile-back{
                    position: absolute;
                    left: 0px;
                    
                }
                .header-container-profile-image-icon {
                    // margin-left: 30%;
                    position: relative;

                    .header-container-profile-complition-perct {
                        padding: 5px 17px;
                        border-radius: 12px;
                        background-color: #FFBE00;
                        @include fontStyle($font-family-barlow, 16px, 500, 19px, 0, #1F262F);
                        position: absolute;
                        bottom: -6px;
                        left: 45px;
                    }
                }
            }

            .header-container-profile-add-sec {
                width: 100%;
                padding: 20px;
                display: flex;
                padding: 0px 50px;
                align-items: center;
                margin-top: 30px;
                justify-content: center;

                .default-address-line-1 {
                    @include fontStyle($fontFamily-default, 14px, 500, 14px, 0, #1F262F);
                    padding-left: 5px;
                    white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                }
            }

            .user-first-name {
                width: 100%;
                margin-top: 8px;
                display: flex;
                justify-content: center;
                @include fontStyle($font-family-barlow, 20px, 500, 24px, 0, #000000);

            }

            .edit-profile-button {
                width: 100%;
                display: flex;
                justify-content: center;

                .button {
                    width: 140px;
                    background-color: #000000;
                    border-radius: 6px;
                    display: flex;
                    justify-content: center;
                    padding: 8px 0px;
                    margin-top: 13px;
                    cursor: pointer;

                    .button-title {
                        @include fontStyle($font-family-barlow, 14px, 500, 17px, 0, #FFFFFF);
                        margin-left: 10px;

                    }
                }
            }
        }
    }
}

.active-profile,
.menu-wrapper {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    width: 80%;
    top: 0;
    left: 100%;
    right: 100%;
    z-index: 999999;
    // background: #FFFFFF;
    height: 100%;
    transition: visibility 0.5s ease, opacity 0.5s ease, right 0.5s ease;
    border-radius: 0px 25px 0px 0px;
    overflow: hidden;

    //Side menu Backgorund need to white 
    // @media screen and (max-width: 1024px) { 
    //     background: #F7F8F9;
    // }

    &.display-menu {
        visibility: visible !important;
        opacity: 1 !important;
        left: 0 !important;
    }



    .menu-item {
        // padding : 0 20px;
        overflow: auto;

        // max-height: 100vh;
        @media screen and (max-width:768px) {
            height: 100%;
            background-color: #FFF !important;
            height: 100%;
    position: relative;
    top: 97px;
        }

        .header-container {
            position: relative;
            // height    : 69px;
            width: 100%;
            background: #FFFFFF;
            z-index: 1;
            background-image: url('../../../public/static/media/common/variant/profile_background.svg');
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 0px 25px 0px 0px;
            padding: 62px 30px 18px 30px;
            display: flex;
            align-items: center;

            .header-container-svg {
                line-height: 0px;
            }

            .header-container-close-icon {
                position: absolute;
                right: 24px;
                top: 24px;
                height: 20px;
                width: 20px;
            }

            .header-container-mbcash-details {
                padding-left: 16px;
                margin-top: 9px;
                display: flex;
                align-items: center;

                .header-container-mbcash-icon {
                    height: 18px;
                    width: 18px;
                    margin-right: 5px;
                }

                .header-container-mbcash {
                    @include fontStyle($fontFamily-default, 14px, 500, 24px, 0, #FFF);
                }

            }

            .header-container-userDetails {
                width: fit-content;
                max-width: 95%;
                .log-head{
                    width: 100%;
                    .signup{
                        padding: 8px 0px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // border: 
                        background: #FFBE00;
                        border-radius: 4px;
                        width: 90%;
                        @include fontStyle($font-family-barlow, 14px, 500, 17px, 0, #20262E);
                        margin-top: 9px;
                        margin-left: 16px;
                        @media screen and (max-width:1024px) {
                            padding: 8px 17px;
                        }
                    }
                }

                .header-container-userDetails-name {
                    width: fit-content;
                    max-width: 200px;
                    display: flex;
                    align-items: center;
                    .header-container-userDetails-name-regular {
                        @include fontStyle($fontFamily-default, 14px, 400, 20px, 0, #FFF);
                        padding-left: 16px;
                        display: flex;
                        max-width: 196px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding-right: 10px;
                        .userDetails-name-svg{
                            display: flex;
                            align-items: center;
                            &.add-space{
                                margin-left: 10px;
                            }
                        }
                    }

                    .header-container-userDetails-name-bold {
                        @include fontStyle($font-family-barlow, 14px, 500, 20px, 0, #FFF);
                        // padding-right: 6px;
                        padding-left: 5px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: fit-content;
                        max-width: auto;
                        display: inline;


                    }

                }
            }


            .header-wrapper {
                margin: 21px 0;
                position: relative;
                height: 26.67px;



                .menu-close {
                    position: absolute;
                    right: 0;
                    top: 0;


                }
            }
        }

        .signup-container {
            margin-top: 70px;

            .signup-wrapper {
                background: rgba($color: #001D42, $alpha: 0.1);
                border-radius: 10px;

                .log-head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    height: 61px;
                    padding: 0 20px 0 26px;

                    span {
                        @include fontStyle($fontFamily-oswald, 22px, 400, 32px, 0, #001D42);
                        text-transform: capitalize;
                    }

                    a.signup {
                        background: #FFD800;
                        padding: 8px 45px 11px;
                        border-radius: 8px;
                        text-transform: uppercase;
                        @include fontStyle($fontFamily-oswald, 14px, 500, 21px, 0, #001D42);
                    }

                    svg {
                        position: absolute;
                        right: 14px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        .header-menu {
            margin-top: 95px;

            @media screen and (max-width: 1024px) {
                margin-top: 0px;

            }

            ul.header-dropdown {
                li.menu-list-container {
                    border-top: 4px solid #F7F7F7;
                    // padding   : 20px 0;
                    position: relative;

                   

                    .new-tag {
                        color: white;
                        background: red;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        font-size: 10px;
                        line-height: normal;
                        padding: 1px 3px;
                        border-radius: 7px 3px 7px 3px;
                        margin-top: 5px;
                        font-weight: 600;
                    }

                    .menu-head {
                        display: flex;
                        align-items: center;
                        @media screen and (max-width: 1024px) {
                            position: relative;
                            padding-bottom: 10px;
                        }
                        .menu-list-icon-container{
                            padding: 8px;
                            border: 1px solid #DBDDE8;
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 15px;
                        }

                        .menu-list-icon-container-bottom-line{
                            height: 2px;
                            border: 2px;
                            background-color: #E8ECEF;
                            width: 80%;
                            position: absolute;
                            bottom: 0px;
                            height: 1px;
                            right: 0px;

                        }

                        .menu-list-icon-container-forword-icon{
                            position: absolute;
                            right: 0px;
                        }

                        img {
                            margin-right: 10px;
                        }

                        span {
                            @include fontStyle($fontFamily-oswald, 20px, 500, 30px, 0, #001D42);
                            text-transform: uppercase;

                            @media screen and (max-width: 1024px) {
                                @include fontStyle($font-family-barlow, 14px, 500, 20px, 0, #161616);
                                text-transform: none;

                            }
                        }

                        &.red {
                            span {
                                color: #ff5127;
                            }
                        }
                    }

                    .menu-list-wrap {
                        // margin-left: 30px;
                        // margin-top : 7px;

                        ul {

                            li.contact-us {
                                margin-bottom: 82px;

                                span{
                                    font-family: "Roboto";
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    letter-spacing: 0;
                                    color: rgb(147,150,153);
                                    margin-left: 15px;
                                }
                            }

                            li.menu-list-item {
                                padding: 7px 0;
                                position: relative;

                                @media screen and (max-width: 1024px) {
                                    display: flex;
                                    align-items: center;
                                    padding: 24px 10px 0px 16px;
                                    flex-wrap: wrap;

                                    // :last-child{
                                    //     margin-bottom: 50px;
                                    // }
                                }

                                .border-bottom {
                                    height: 4px;
                                    background-color: #F7F7F7;
                                    position: absolute;
                                    width: 70%;
                                    bottom: 0px;
                                }

                                a {
                                    svg {
                                        position: absolute;
                                        right: 4px;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                    
                                    span {
                                        @include fontStyle($fontFamily-default, 18px, 500, 24px, 0, #001D42);
                                        text-transform: uppercase;
                                        @media screen and (max-width: 1024px) {
                                            @include fontStyle($fontFamily-default, 14px, 600, 20px, 0, rgb(147,150,153));
                                            margin-left: 15px;
                                        }
                                    }:hover{
                                        color: #000000;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .log-out-action {
                margin: 37px 0;

                .log-out {
                    @include fontStyle($fontFamily-oswald, 14px, 500, 21px, 0, rgb(147,150,153));
                    text-transform: uppercase;
                    padding: 9px 45px 10px;
                    border: 1px solid #001D42;
                    border-radius: 8px;
                }
            }

            &.login {
                opacity: 0;
                visibility: hidden;
                height: 0;
                transition: opacity 0.5s ease, visibility 0.5s ease;

                &.show {
                    // height: auto;
                    opacity: 1;
                    visibility: visible;
                }

                ul.header-dropdown {
                    border-top: 1px solid rgba($color: #001D42, $alpha: 0.1);
                    border-bottom: 1px solid rgba($color: #001D42, $alpha: 0.1);
                    padding: 10px 0 30px;

                    @media screen and (max-width: 1024px) {
                        position: absolute;
                        top: 350px;
                        left: 20px;
                        z-index: 999;
                        background: #FFF;
                        border-radius: 10px;
                        box-shadow: 0px 3px 20px #3941491A;
                        width: 90%;
                        padding: 16px 0px;
                        
                    }

                    li.menu-list-container {
                        border: none;
                        padding: 10px 21px;

                        span.mbcash {
                            margin-left: 12px;
                            background: rgba($color: #E5E8EC, $alpha: 0.5);
                            border-radius: 10px;
                            padding: 5px 12px;
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 5px;
                            }

                            span {
                                padding-bottom: 2px;
                                @include fontStyle($fontFamily-oswald, 14px, 500, 21px, 0, #001D42);
                            }
                        }
                    }
                }

                .log-out-action {
                    margin-top: 16px;

                    @media screen and (max-width: 1024px) {
                        position: absolute;
                        top: 820px;
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        .log-out{
                            width: 90%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 13px 0px;
                            border: 1px solid #53566480;
                            border-radius: 2px;
                            margin-bottom: 30px;
                        }
                    }
                }
            }

            &.left {
                opacity: 0;
                visibility: hidden;
                height: 0;
                transition: opacity 0.5s ease, visibility 0.5s ease;

                &.show {
                    height: auto;
                    opacity: 1;
                    visibility: visible;
                }
            }

            &.right {
                margin-top: 88px;

                @media screen and (max-width: 1024px) {
                    margin-top: 0px;

                }

                .selected-list {
                    .title {
                        @include fontStyle($fontFamily-default, 20px, 500, 26px, 0, #001D42);
                        padding-bottom: 6px;

                        &.none {
                            display: none;
                        }

                        &.block {
                            display: block;
                        }
                    }

                    ul.selected-menu-list {
                        li {
                            padding: 18px 0;
                            border-bottom: 1px solid rgba($color: #001D42, $alpha: 0.1);

                            a {
                                @include fontStyle($fontFamily-default, 16px, 400, 21px, 0, #001D42);
                                display: flex;
                                align-items: center;

                                img {
                                    margin-right: 20px;
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }

                        &.none {
                            display: none;
                        }

                        &.block {
                            display: block;
                        }
                    }

                }
            }
        }
    }

}

// search overlay
div#hk-search-box-result {
    font-size: 13px;
    background: #fff;
    border: 1px solid #d3d4d5;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .08);
    vertical-align: top;
    padding: 20px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    z-index: 99999;
    max-width: 1170px;
    left: 0;
    top: 79px;
    margin-top: 0 !important;
    right: 0;
    height: 390px;
    margin: 0 auto;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        top: -42px;
        border-color: transparent transparent #c1c1c1 transparent;
        border-width: 21px;
        left: 220px;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 221px;
        width: 0;
        height: 0;
        border-style: solid;
        top: -40px;
        border-color: transparent transparent #fff transparent;
        border-width: 20px;
    }

    .text-sugg {
        width: 33%;
        height: 360px;
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
    }

    .img-sugg {
        height: 350px;
        display: inline-block;
        overflow: hidden;
        width: 66%;
        border-left: 1px solid #efefef;
        vertical-align: top;
        padding-left: 6px;
    }

    .srch-box-topic {
        color: #c1c1c1;
        margin-bottom: 10px;
        vertical-align: top;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }

    .trnding-items {
        height: 30px;
        font-size: 13px;
    }

    .trnding-items a>span {
        font-size: 13px;
        font-weight: 400;
    }

    .trnding-items a:hover span {
        color: #177a99;
    }

    .text-sugg {
        width: 33%;
        height: 360px;
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
    }

    .img-sugg {
        height: 350px;
        display: inline-block;
        overflow: hidden;
        width: 66%;
        border-left: 1px solid #efefef;
        vertical-align: top;
        padding-left: 6px;
    }

    .srch-box-topic {
        color: #c1c1c1;
        margin-bottom: 10px;
        vertical-align: top;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }

    .trnding-items {
        height: 30px;
        font-size: 13px;
        position: relative;
    }

    .trnding-items span {
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
    }

    .trnding-items span.clar.clar3.hidden {
        cursor: pointer;
        position: absolute;
        left: auto;
        right: 15px;
        top: 0px;
        padding-left: 5px;
        color: #c2c2c2;
        background-color: #fff;
        display: none;
    }

    .trnding-items img.clq {
        height: 12px;
        margin-left: 1px;
        vertical-align: middle;
    }

    .trnding-items:hover span.clar.clar3.hidden {
        display: block;
    }

    .trnding-items a span:hover {
        color: #177a99;
    }

    span.img-box-topic {
        position: absolute;
        padding-left: 10px;
        color: #c1c1c1;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 13px;
    }

    .trnd-prod {
        height: 75%;
        margin: 77px 0 0 14px;
        display: inline-block;
        max-width: 23%;
        vertical-align: top;

        * {
            font-size: 13px;
        }

        a.tprod {
            display: inline-block;
            width: 100%;
            position: relative;
            padding-top: 5px;
        }

        .prodFlash {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 0;
        }

        .prodFlash * {
            font-size: 13px;
        }

        .prodFlash .flash-icon {
            display: inline-block;
        }

        .flash-icon img {
            height: 20px;
        }

        .prodFlash .qty-left {
            text-align: right;
            display: inline-block;
            border-bottom: 1px solid #f5f4f4;
            display: none;
        }

        span.prodname {
            font-size: 13px;
            font-weight: 400;
            line-height: normal;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            white-space: normal;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;

            b {
                font-weight: 600;
            }
        }
    }

    img.prodimg {
        max-height: 115px;
    }

    span.prodmrp {
        font-size: $fontSize-default;
        font-weight: bolder;
        line-height: normal;
    }

    span.prodBxgy img {
        width: 16px;
        display: inline-flex;
        vertical-align: middle;
    }

    span.prodFreebie img {
        width: 16px;
        display: inline-flex;
        vertical-align: middle;
    }

    span.prodFreebie {
        color: #9AD36C;
    }

    span.prodBxgy {
        color: #FF6866;
    }

    a.tprod:hover {
        color: #177a99;

        span {
            color: #177a99;
        }
    }

    span.fnt-blue {
        color: #00bfbf !important;
    }

    span.srch-freebie {
        position: relative;

        span.freebie-tooltip {
            position: absolute;
            width: 185px;
            left: 0;
            top: 0;
            vertical-align: top;
            visibility: hidden;
            opacity: 0;
            font-size: 11px;
            height: auto;
            padding: 5px 0 5px 5px;
            border: 1px solid #d3d4d5;
            text-align: left;
            line-height: 11px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -o-transition: left 0.3s linear, opacity 0.4s linear;
            -webkit-transition: left 0.3s linear, opacity 0.4s linear;
            transition: left 0.3s linear, opacity 0.4s linear;
            -o-text-overflow: initial !important;
            text-overflow: initial !important;
            white-space: initial !important;
            background: #fff;
            overflow: visible !important;
            color: #25292b;
            min-height: 25px;
        }

        &:hover {
            span.freebie-tooltip {
                visibility: visible;
                opacity: 1;
                left: 25px;
            }

            span.freebie-tooltip:before {
                position: absolute;
                content: "";
                border-right: 5px solid #d3d4d5;
                border-left: 5px solid transparent;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                top: 7px;
                left: -10px;
            }

            span.freebie-tooltip:after {
                position: absolute;
                content: "";
                border-right: 5px solid #fff;
                border-left: 5px solid transparent;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                top: 7px;
                left: -8px;
            }
        }
    }
}

.cart-total {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 400px;
    z-index: 9999;
    background: white;
    padding: 0 35px;
    display: none;
}

.right-menu-footer {
    @extend .right-menu-heading;
    top: auto;
    bottom: 0;
    box-shadow: 0 -3px 6px $grey-35;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cart-total-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .sub-total {
        font-size: 16px;
        font-weight: bold;
    }

    .amount {
        font-size: 16px;
        font-weight: bold;
    }
}

/* car menu mobile */
.mobile-view {
    .site-menu-wrap {
        padding-bottom: 0px;
    }

    .site-menu-wrap,
    .right-menu-heading,
    .cart-menu,
    .right-menu-footer {
        width: 90%;
    }

    .cart-menu .vertical-menu-inner {
        padding-bottom: 10px !important;
    }

    .right-menu-heading {
        h3 {
            font-size: 20px;
            border-bottom: 1px solid #F2F2F2;
            padding-top: 12px;
            padding-bottom: 12px;
            display: flex;
            justify-content: space-between;
        }

        .close-btn {
            margin-left: 20px;
            vertical-align: middle;
        }
    }

    .cart-page-variants-list {
        box-shadow: none;
        border-radius: 0;
    }

    @media screen and (max-width:480px) {
        .cart-coupon-section {
            margin-bottom: 20px;

            &.text-section {
                margin-bottom: 0px;
            }

            .text {
                white-space: nowrap;
                color: $black;
                font-weight: $semibold;
            }

            .form-group {
                width: 100%
            }
        }
    }
}

.right-menu-item {
    position: relative;
}

.header-sprite.header-sprite-cart.cart.Proceed_to_cart_header {
    position: relative;

    #semiBolditalic_H {
        background: $orange2;
        right: 0px;
        top: 1px;
        text-align: center;
        width: 16px;
        height: 16px;
        line-height: 1.8;
        border-radius: 10px;
        display: inline-block;
        position: absolute;
        font-size: 11px;
        color: #fff;
        pointer-events: none;
    }

    &.desktop-cart-icon {
        #semiBolditalic_H {
            right: 2px;
            top: -8px;
            pointer-events: none;
        }
    }
}

/* profile-menu */
.checkout-address-box {
    .accordion-wrap {
        max-width: 700px;

        .usr-add {
            padding: 0 15px;
        }
    }
}

.offermenu-content {
    padding: 20px;

    .col-same-height {
        width: 100%;
        text-align: center;
        margin: 0 5px;
        max-width: 230px;
        padding: 20px 5px;
        border: 1px transparent solid;

        &:nth-child(odd) {
            background: $grey-28
        }

        &:nth-child(even) {
            background: $grey-30
        }

        &:hover {
            box-shadow: 0 3px 6px $grey-31;
            border: 1px solid $grey-32;
        }
    }

    .img-wrap {
        text-align: center;
        align-items: flex-end;

        img {
            max-width: 100%;
            height: 38px;
        }
    }

    a {
        color: $black;
        font-size: 17px;
        font-weight: $medium;
    }
}

.performance .a {
    stroke: #fff
}

.weightloss .a {
    fill: #faf9f8;
}

.search-icon-wrap {
    position: relative;
    z-index: 1;
}

/* mobile serarch menu */
.search-menu-wrap {
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .e-mob {
        background: $white;
        position: relative;

        .form-control {
            background: transparent;
            color: $black;
            border: 0px;
            border-radius: 0;
            font-size: 20px;
            padding-left: 40px;
            padding-right: 40px;
        }

        .search-icon,
        .close-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border: 1px white solid;
            z-index: 99;
        }

        .search-icon {
            left: 10px;
        }

        .close-icon {
            right: 10px;
        }
    }

    .menu-search-list {
        background: $white;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px 13px;
        font-size: 12px;

        #hk-search-box-result {
            position: static;
            padding: 0;
            border: 0;
            box-shadow: none;

            &::before,
            &::after {
                display: none;
            }
        }

        .text-sugg {
            width: 100% !important;
            box-shadow: none;

            .srch-box-topic {
                color: $black7 !important;
                font-size: 14px;
            }
        }
    }
}

.mobile-top-menu-item {
    margin-left: 20px;

    img {
        width: 22px;
    }
}

.menu-wrapper .menu-item .header-dropdown-wrap .header-dropdown>li.dropdown-menu>a span.no-arrow:after {
    display: none;
}

.srch-box-items {
    margin: 5px 0 30px 0;
    display: flex;
    flex-wrap: wrap;

    .srch-box-items-list {
        li {
            margin-bottom: 10px;

            a {
                font-size: 15px;
                font-weight: 400;
                color: $black7 !important;
                line-height: 18px;
                display: block;
            }
        }
    }

    .trnding-items {
        margin: 10px 0;
        background: $grey-25;
        padding: 2px 7px;
        margin-right: 10px;
        width: auto;
        height: auto !important;
        line-height: 18px;

        &>a {
            font-size: 12px;
            font-weight: 400;
            color: $black7 !important;
            line-height: 18px;
        }
    }
}

.main-header-wrapper {
    .header-sprite-logo {

        .a,
        * {
            fill: #fff;
            font-size: 20px;
            font-weight: 700;
        }
    }

    .header-top {
        height: auto;

        &.minHeight {
            min-height: 54px;
        }

        .hk_logo_header {
            margin-left: -15px;
        }

        .header-icon-right {
            display: flex;
            align-items: center;

            .a,
            .a * {
                fill: #fff;
            }

            .tbs-search {
                margin-left: 10px;
            }
        }

        .logo-menu-wrapper {
            position: fixed;
            top: 0px;
            left: 0;
            width: 100%;
            z-index: 9999;
            background: transparent;
            height: 54px;
            align-items: center;
            border-bottom: 3px solid transparent;

            .tr-menu-mob {
                margin: 11px 11px 0 0;
            }

            .header-flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 15px;
                height: 54px;

                @media screen and (min-width:1024px) {
                    padding: 0;
                }
            }

            .a {
                fill: #fff;
            }

            .defaultLogo {
                display: none;
            }

            &.defaultHeader {
                background: $white;
                border-bottom: 1px $grey-6 solid;

                .menu-bar {
                    a {
                        color: $white;
                        padding: 0 15px;
                    }
                }

                .a {
                    fill: $black;
                }

                .defaultLogo {
                    display: flex;
                }

                .brandLogo {
                    display: none;
                }

                .hk_logo_header {
                    margin-left: 0;
                }
            }
        }

        a.header-sprite.header-sprite-cart.cart.Proceed_to_cart_header {
            position: relative;

            span#semiBolditalic_H {
                position: absolute;
                top: -12px;
                left: 15px;
                background: $primary-color;
                width: 14px;
                height: 14px;
                line-height: normal;
                border-radius: 50%;
                text-align: center;
                color: #fff;
                font-weight: 600;
                pointer-events: none;
                font-size: 12px;
            }
        }
    }
}

.gritzo-cart-menu{
    position: absolute !important;
    top: 111px !important;
    right: 40px !important;
    width: 368px !important;
    height: 368px !important;
    min-width: 368px !important;
    padding: 25px 35px !important;
    background: #fff !important;
    .vertical-menu-inner {
        .menu-actual-content {
            overflow-y: hidden;
            height: unset;
            max-height: unset;
        }
        .vertical-menu-header {
            .menu-heading {
                .close-btn{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }
        }
        .new-menu-heading{
            svg{
                width: 13px;
                margin: 0 10px 0 0;
            }
        }
    }
}