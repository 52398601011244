@import '../../../../../static/css/global/global.scss';

.header-top {
    &__container {
        background-color: #fff;
        height          : 91.34px;

        .header-side-menu {
            position: relative;

            .extra-path {
                height          : 18px;
                width           : 170px;
                background-color: transparent;
                position        : absolute;
                top             : -16px;
                right           : -32px;
            }
        }

        &>.container-wrap {
            color             : #FFFFFF;
            //  height: 60px;
            //top: 80px;
            display           : flex;
            flex-wrap         : wrap;
            justify-content   : center;
            // justify-content: space-between;
            align-items       : center;
            // padding: 10px 0;
            padding           : 12px 0;
            transition        : all 0.5s;
            position          : relative;
            max-width         : 1160px;

            a {
                line-height: 0;
            }

            .header__submenu {
                opacity         : 1;
                /* transform    : translateY(0); */
                animation       : animateMenuOpen var(--duration-default) ease;
                display         : flex;
                width           : fit-content;
                width           : -webkit-fit-content;
                width           : -moz-fit-content;
                padding         : 3rem 3rem;
                box-shadow      : 0px 5px 8px rgba(0, 0, 0, 0.2) !important;
                /* position     : absolute; */
                /* min-width    : 100%; */
                /* width        : 20rem; */
                border          : 1px solid rgba(147,150,153, 0.2);
                background-color: #fff;
                position        : absolute;
                top             : 74%;
                left            : 16%;
                width           : 70%;
                justify-content : space-between;
                align-items: center;
                min-height: 140px;
                .first-menu {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
                .menu-section {
                    .menu-head {
                        font-size     : 14px;
                        line-height   : 20px;
                        color         : var(--color-text-second);
                        text-transform: uppercase;
                        font-weight   : 600;
                        font-family   : var(--font-body-family);
                    }

                    span {
                        .list-menu__item {
                            display    : flex;
                            align-items: center;
                            line-height: 1.3;
                        }

                        .header__menu-item {
                            color         : #282960;
                            font-family   : Barlow;
                            font-size     : 14px;
                            font-style    : normal;
                            font-weight   : 700;
                            line-height   : normal;
                            text-transform: uppercase;
                        }:hover{
                            color: #262D33;
                        }
                    }
                }

                .subset-menu {
                    margin-left: 3rem;
                    max-width  : 18rem;

                    .menu-category-banner {
                        display      : block;
                        margin-bottom: 1.5rem;

                        img {
                            border-radius: 4px;
                            object-fit   : contain;
                            height       : auto;
                            cursor       : pointer;
                            mix-blend-mode: multiply;
                        }
                    }
                }
            }
        }
    }

    &__nav {
        height  : 42px;
        // width: 400px;
        margin  : 0 40px 0 0;
        flex: 1;

        .search-bar {
            // border-radius: 6px;
            // padding: 15px 16px;
            width   : 100%;
            height  : inherit;
            position: relative;
            max-width: 300px;
            margin-left: auto;
            svg {
                position : absolute;
                right    : 15px;
                top      : 50%;
                transform: translateY(-50%);
            }

            .search-input-text-box-form {
                width    : 100%;
                height   : inherit;
                max-width: 300px;

                .search-input-text-box {
                    @include fontStyle($fontFamily-default, 16px, $medium, 27px, 0px, #414C58);
                    width        : 100%;
                    background   : #ffffff;
                    border       : 1px solid #82828261;
                    border-radius: 6px;
                    padding      : 0 40px 0 12px;
                    height       : inherit;
                }
            }
        }

        strong,
        h2,
        .h2 {
            @include fontStyle($fontFamily-oswald, 16px, 500, 27px, 0px, $color-white);
            cursor     : pointer;
            // height: 58px;
            white-space: nowrap;
            position   : relative;

            .new-tag {
                position     : absolute;
                color        : white;
                background   : red;
                position     : absolute;
                bottom       : 100%;
                right        : 0;
                font-size    : 10px;
                line-height  : normal;
                padding      : 1px 3px;
                border-radius: 7px 3px 7px 3px;
                margin-bottom: -2px;
                font-weight  : 600;
            }

            &:hover {
                .header-top__menu {
                    visibility: visible;
                    opacity   : 1;
                    transition: opacity .5s ease, visibility .5s ease;
                }

                .keep-menu-hidden {
                    display: none;
                }
            }
        }

        .lwl-des {
            position    : relative;
            padding-left: 25px;

            svg {
                position: absolute;
                top     : 1px;
                left    : -5px;
            }
        }
    }

    position: relative;
    left    : 20px;

    &__icons {
        display    : flex;
        column-gap : 32px;
        margin-left: 20px;
        position   : relative;
        // left: 28px;
        align-items: center;
        cursor     : pointer;

        div {
            display       : flex;
            align-items   : center;
            // column-gap: 23px;
            // margin-left: auto; 


        }

        &__cart {
            position   : relative;
            display    : flex;
            align-items: center;
            cursor     : pointer;
            svg{
                width: 20px;
                height: 20px;
            }
            span {
                display         : flex;
                justify-content : center;
                align-items     : center;
                position        : absolute;
                bottom          : 14px;
                right           : -15px;
                width           : 20px;
                height          : 20px;
                background-color: #3dc47e;
                // border: 1px solid $color-primary;
                border-radius   : 50%;
                text-align      : center;
                @include fontStyle($fontFamily-default, 10px, 600, 15px, 0px, #FFF);

                @media screen and (max-width: 1024px) {
                    height       : 15px;
                    width        : 16px;
                    background   : #3dc47e;
                    border-radius: 8px;
                    // height: 40px;
                    padding      : 2px 3px;
                    @include fontStyle($fontFamily-default, 9px, 600, 24px, 0px, #FFF);
                    img{
                        position: relative;
                        top: 2px;
                    }
                }
            }
        }

        .user-info-section-data {
            flex-direction: column;
            align-items   : flex-start;
            padding-left  : 15px;

            .user-info-data {
                @include fontStyle($fontFamily-default, 16px, 500, 20px, 0px, #20262E);

            }

            .user-info-data-nm {
                align-items  : center;
                white-space  : nowrap;
                overflow     : hidden;
                text-overflow: ellipsis;
                max-width    : 96px;
                width        : auto;
                text-align   : left;
                padding-right: 5px;
                @include fontStyle($font-family-barlow, 16px, 500, 20px, 0px, #20262E);

                @media screen and (min-width: 1024px) {
                    text-align: start;
                }
            }

            svg {
                margin-left: 4px;
            }

            .active-profile-page {
                transform: rotateX(180deg)
            }

        }

        .btn-white {
            background-color: transparent;
            border          : none;
            // border: none !important;
            // align-items: center;
            // svg{
                width: 20px;
                height: 80px;
      }

        .mb-btn {
            height         : auto !important;
            border-radius  : 4px;
            padding        : 11px 18px 11px 0px;
            display        : flex;
            justify-content: center;
            width          : 162px;

            @include fontStyle($font-family-barlow, 16px, 500, 17px, 0px, #20262E);
            background-color: #FFBE00;

            @media screen and (min-width: 1024px) {
                padding        : 11px 0px 11px 0px;
                min-width      : 142px;
                min-height     : 40px;
                display        : flex;
                align-items    : center;
                justify-content: flex-start;
            }


            .bordre-bottom {
                border-bottom: 6px solid #FFBE00;
                width        : 51%;
                position     : absolute;
                top          : 63px;
            }

            &.acc {
                display          : flex;
                justify-content  : flex-start;
                align-items      : center;
                // column-gap: 5px;
                padding          : 0;
                width            : 179px;
                // border-bottom: 6px solid #FFBE00;
                // padding-bottom: 24px;
            }
        }

        .mb-cash {
            position: relative;

            .amount {
                color        : #FFF;
                background   : #51B279;
                position     : absolute;
                bottom       : -6px;
                right        : -14px;
                font-size    : 10px;
                line-height  : normal;
                padding      : 1px 5px;
                border-radius: 8px;
                margin-top   : 5px;
                font-weight  : 600;
                line-height  : 17px;
            }
        }
    }

    &__close-search-btn {
        position: absolute;
        right   : 0;
        cursor  : pointer;
    }

    &__menu {
        visibility      : hidden;
        opacity         : 0;
        background-color: white;
        z-index         : 4;
        position        : fixed;
        right           : 0;
        top             : 44px;
        width           : 100vw;
        left            : 50%;
        transform       : translateX(-50%);
        box-shadow      : 0px 5px 20px #0000001A;
    }
}


.header-headings {
    display       : flex;
    color         : #282960;
    font-family   : $fontFamily-default;
    font-size     : 14px;
    font-style    : normal;
    font-weight   : 700;
    line-height   : normal;
    text-transform: uppercase;
    margin        : 0 10px;
    // margin-top: 7px;
    white-space   : nowrap;

    .blog-heading {
        margin: 17px 10px;
        @media screen and (min-width: 1024px) {
            min-width: 30px;
        }

    }
    .blog-heading-no-data{
        width: 111px;
        height: 15px;
        background-color: #ffffff;
    }

    .blog-link,
    .contactus-link,
    .aboutus-link {
        color      : #282960;
        white-space: nowrap;
        font-size  : 13px;
        font-weight: 600;
        cursor: pointer;
    }

    :hover {
        opacity: .9;
        color  : #000;
        text-decoration: underline;
    }
    .active{
        color: #230c71a3 !important;
        text-decoration: underline !important;
    }

    .blog-link {
        svg {
            width: 12px;
        }
    }

    .contactus-heading {
        margin: 17px 10px;
    }

    .aboutus-heading {
        margin: 17px 10px;
    }
}

.header-headings a:hover {
    color          : black;
    font-weight    : bold;
    text-decoration: underline;
}

.header-personalized {
    border       : 2px solid #f47b259e;
    white-space  : nowrap;
    margin-right : 28px;
    padding      : 6px 9px;
    border-radius: 9px;
    margin-left  : 18px;
    @media screen and (min-width:1023px) {
        width: 199px;
        height: auto;
    }

    .personalised-headerlink {
        color    : #E57321;
        font-size: 11px;
    }
}

.user-icon-cntnr {
    position: relative;

    .loyalty-icon{
        position: absolute;
        top: -5px;
        right: -10px;    
    }

    .loyalty-icon-logged-in{
        @extend .loyalty-icon;
        top: 20px;

        @media screen and (min-width: 1024px) {
            height: 20px;
            right: -14px;
            top: 18px;
        }
    }
}